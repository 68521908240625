// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ScrollToTop } from '@orascom/common-components';
import { ROUTES } from '../api/routes';
import styles from './app.module.scss';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import { Header } from '@orascom/broker-sales-man-common-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import CompareUnitsProvider from 'libs/broker-sales-man-common-components/src/contexts/compare-units-provider';
import { CurrencyContextProvider } from '@orascom/utils';

export function App() {
  const showSidebar = true; // TODO: change when users are added
  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      if (!route.public) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);
  return (
    // TODO : condition on user in classname
    <CompareUnitsProvider>
      <CurrencyContextProvider>
        <main className={`${true ? 'container container--flex' : 'container'}`}>
          <Sidebar />
          <ScrollToTop />
          <section className={styles['side-section']}>
            {showSidebar && (
              <Header notificationPath={ROUTES['Notifications'].path} />
            )}
            <Routes>{availableRoutes}</Routes>
          </section>
        </main>
      </CurrencyContextProvider>
    </CompareUnitsProvider>
  );
}

export default App;
