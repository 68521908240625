import {
  DestinationFacilities,
  DestinationFAQ,
  Destination as DestinationInterface,
  DestinationProject,
  PaginationPayload,
  Payload,
  Location,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';

export class Destination {
  /**
   * list all destinations data from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationInterface[]>>}
   */
  public static listSalesManDestinations(): Promise<
    Payload<DestinationInterface[]>
  > {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listSalesManDestinations.url}`,
      {
        method: ENDPOINTS.listSalesManDestinations.method,
      }
    );
  }

  public static getSalesManDestinationInfo(
    destinationSlug: string
  ): Promise<Payload<DestinationInterface>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManDestinationInfo.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getSalesManDestinationInfo.method,
      }
    );
  }

  public static getSalesManDestinationLocations(
    destinationSlug: string
  ): Promise<Payload<Location[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManDestinationLocations.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getSalesManDestinationLocations.method,
      }
    );
  }

  public static getSalesManDestinationFacilities(
    destinationSlug: string
  ): Promise<Payload<DestinationFacilities[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManDestinationFacilities.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getSalesManDestinationFacilities.method,
      }
    );
  }

  public static getSalesManDestinationProjects(
    destinationSlug: string
  ): Promise<Payload<DestinationProject[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManDestinationProjects.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getSalesManDestinationProjects.method,
      }
    );
  }

  public static getSalesManDestinationFAQ(
    destinationSlug: string
  ): Promise<Payload<PaginationPayload<DestinationFAQ[]>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManDestinationFAQ.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getSalesManDestinationFAQ.method,
      }
    );
  }
}
