import styles from './deal-details.module.scss';

/* eslint-disable-next-line */
export interface DealDetailsProps {}

export function DealDetails(props: DealDetailsProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to DealDetails!</h1>
    </div>
  );
}

export default DealDetails;
