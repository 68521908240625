/**
 * @author Salma Hefnawy
 * @date 2024-09-01
 * @description Implementation of analytics events related utilities.
 * @filename analytics-events.ts
 */

import { CommonEventParameters } from '@orascom/utils';

export function analyticsCreateLeadCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'createlead', params);
  } catch (e) {
    console.error('Error in analyticsCreateDealCustomEvent', e);
  }
}
export function analyticsSalesManDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `destination_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationCustomEvent', e);
  }
}

export function analyticsSalesManDestinationsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'destinations', params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationsCustomEvent', e);
  }
}

export function analyticsSalesManPrimaryUnitsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'primary_units', params);
  } catch (e) {
    console.error('Error in analyticsPrimaryUnitsCustomEvent', e);
  }
}
