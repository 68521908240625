import { LeadStatus } from '../definitions/interfaces/deals.interface';
import greenBulletIcon from '../assets/icons/green-bullet.svg';
import blueBulletIcon from '../assets/icons/navy-blue-bullet.svg';
import grayBulletIcon from '../assets/icons/gray-bullet.svg';

export const renderLeadStatusIcon = (status: string) => {
  switch (status) {
    case LeadStatus.LEAD_DISCOVERY:
      return (
        <div>
          <img src={grayBulletIcon} alt="Late" />
          {''}
          Lead Discovery
        </div>
      );
    case LeadStatus.SALES_PITCH:
      return (
        <div>
          <img src={blueBulletIcon} alt="Pending" />
          {''}
          Sales Pitch
        </div>
      );
    case LeadStatus.DEAL_CLOSING:
      return (
        <div>
          <img src={greenBulletIcon} alt="Pending" />
          {''}
          Deal Closing
        </div>
      );
    default:
      return null;
  }
};
