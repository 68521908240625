import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SelectDropdown } from '@orascom/common-components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import newDealImg from '../../assets/images/new-deal.webp';
import styles from 'libs/broker-sales-man-common-components/src/styles/new-deal.module.scss';
import 'react-phone-input-2/lib/style.css';
import { MultiplePhoneNumbersFields } from '@orascom/broker-sales-man-common-components';

export function NewDeal() {
  const { t } = useTranslation();

  const phoneSchema = yup.object({
    countryCode: yup.string().required(),
    phone: yup.string().required(t('pleaseEnterPhoneNumber')),
  });

  const schema = yup.object({
    firstName: yup.string().required(t('pleaseEnterFirstName')),
    lastName: yup.string().required(t('pleaseEnterLastName')),
    phoneNumbers: yup.array().of(phoneSchema).max(3, 'maxThreePhoneNumbers'),
    unitType: yup.object().nullable().required(t('required')),
    leadChannel: yup.object().nullable().required(t('required')),
  });

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('newLead')}</h1>
      <div className={styles['grid']}>
        <div className={styles['form__wrapper']}>
          <h3>{t('leadInformation')}</h3>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              unitType: null,
              leadChannel: null,
              phoneNumbers: [{ countryCode: '20', phone: '' }],
              ambassadorName: '',
              additionalInfo: '',
            }}
            validationSchema={schema}
            onSubmit={(values) => console.log(values)}
          >
            {({ errors, setFieldValue, values, touched, isValid }) => (
              <Form className={styles['form']}>
                <div className="form__input">
                  <label htmlFor="firstName">{t('firstName')}</label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    className={
                      errors.firstName && touched.firstName ? 'error' : ''
                    }
                    placeholder={t('prospectiveBuyerFirstName')}
                  />

                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="lastName">{t('lastName')}</label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    className={
                      errors.lastName && touched.lastName ? 'error' : ''
                    }
                    placeholder={t('prospectiveBuyerLastName')}
                  />

                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="form__error"
                  />
                </div>

                <MultiplePhoneNumbersFields
                  phoneNumbers={values.phoneNumbers}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />

                <div className="form__input">
                  <label>{t('unitType')}</label>
                  <SelectDropdown
                    options={[
                      { value: 'apartment', label: 'Apartment' },
                      {
                        value: 'penthouse',
                        label: 'Penthouse',
                      },
                      { value: 'villa', label: 'Villa' },
                    ]}
                    placeholder={t('searchUnitType')}
                    className={styles['select']}
                    isClearable
                    onChange={(option) => {
                      setFieldValue('unitType', option);
                    }}
                    selectedOption={values.unitType || null}
                  />

                  <ErrorMessage
                    name="unitType"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className="form__input">
                  <label htmlFor="leadChannel">{t('leadChannel')}</label>
                  <SelectDropdown
                    name="leadChannel"
                    className={`${styles['select']} ${
                      errors.leadChannel && touched.leadChannel ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('leadChannel', option)}
                    options={[
                      {
                        value: 'ambassador',
                        label: 'Ambassador',
                      },
                      { value: 'broker', label: 'Broker' },
                      { value: 'sales', label: 'Sales man' },
                    ]}
                    selectedOption={values.leadChannel || null}
                    isClearable
                  />

                  <ErrorMessage
                    name="leadChannel"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className="form__input">
                  <label htmlFor="ambassadorName">
                    {t('ambassadorName')} ({t('optional')})
                  </label>
                  <Field
                    id="ambassadorName"
                    name="ambassadorName"
                    type="text"
                    className={
                      errors.ambassadorName && touched.ambassadorName
                        ? 'error'
                        : ''
                    }
                    placeholder={t('ambassadorNamePlaceholder')}
                  />

                  <ErrorMessage
                    name="ambassadorName"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className="form__input">
                  <label htmlFor="additionalInfo">
                    {t('additionalInfo')} ({t('optional')})
                  </label>
                  <Field
                    id="additionalInfo"
                    name="additionalInfo"
                    as="textarea"
                    className={styles['textarea']}
                    placeholder={t('additionalNotes')}
                  />

                  <ErrorMessage
                    name="additionalInfo"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className={styles['button-wrapper']}>
                  <button
                    className="btn btn--navy"
                    type="submit"
                    disabled={!isValid}
                  >
                    {t('createLead')}
                  </button>
                  <Link to={ROUTES['MyDeals'].path} className="anchor">
                    {t('cancel')}
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <img
          src={newDealImg}
          alt="new-deal"
          className={styles['image']}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default NewDeal;
