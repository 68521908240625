import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { Payload, UnitFilters } from '@orascom/api-interfaces';

/**
 * implement filters related calls.
 */

export class Filter {
  public static getSalesManUnitFilters(
    searchParams?: URLSearchParams
  ): Promise<Payload<UnitFilters>> {
    const baseUrl = process.env['NX__SALESMAN_API_ENDPOINT'];
    const filtersEndpoint = ENDPOINTS.getSalesManFilters.url;
    const url = new URL(`${baseUrl}${filtersEndpoint}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    return Network.fetch(url.href, {
      method: ENDPOINTS.getSalesManFilters.method,
    });
  }
}
