import {
  PaginationPayload,
  Payload,
  UnitInterface,
} from '@orascom/api-interfaces';
import { Network } from './network';
import { ENDPOINTS } from './endpoints';

export class Unit {
  public static getSalesManUnits(
    searchParams?: URLSearchParams | string
  ): Promise<Payload<PaginationPayload<UnitInterface[]>[]>> {
    const baseUrl = process.env['NX__SALESMAN_API_ENDPOINT'];
    const unitsEndpoint = ENDPOINTS.getSalesManUnits.url();

    const url = new URL(`${baseUrl}${unitsEndpoint}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    return Network.fetch(url.href, {
      method: ENDPOINTS.getSalesManUnits.method,
    });
  }

  public static addSalesManSavedUnit(unitId: number): Promise<Payload<void>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.addSalesManSavedUnit.url(unitId)}`,
      {
        method: ENDPOINTS.addSalesManSavedUnit.method,
      }
    );
  }

  public static deleteSalesManSavedUnit(
    unitId: number
  ): Promise<Payload<void>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.deleteSalesManSavedUnit.url(unitId)}`,
      {
        method: ENDPOINTS.deleteSalesManSavedUnit.method,
      }
    );
  }
}
