import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './my-deals.module.scss';
import { MultiSelectDropdown } from '@orascom/common-components';
import {
  LeadStatus,
  statusesOptions,
} from '../../definitions/interfaces/deals.interface';
import { OptionValue } from '@orascom/api-interfaces';
import plusIcon from '../../assets/icons/plus--white.svg';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { CommonEventParameters } from '@orascom/utils';
import { analyticsCreateLeadCustomEvent } from '../../utils/analytics-events';
import { ROUTES } from '../../api/routes';
import { renderLeadStatusIcon } from '../../utils/deal.utils';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';

export function MyDeals() {
  const { t } = useTranslation();
  const [selectedStatuses, setSelectedStatuses] = useState<OptionValue[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Todo: all this will be dynamic after integration
  const analyticsEventParams: CommonEventParameters = {
    userId: '1',
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };

  const leadsData = [
    {
      leadName: 'Ahmed Serag',
      unitType: 'Apartment, Penthouse',
      date: '12 August 2024', // Original string format
      leadStatus: LeadStatus.LEAD_DISCOVERY,
    },
    {
      leadName: 'Yousef Abougabal',
      unitType: 'Standalone Villa',
      date: '10 August 2024',
      leadStatus: LeadStatus.LEAD_DISCOVERY,
    },
    {
      leadName: 'Mahmoud Aly',
      unitType: 'Apartment, Penthouse',
      date: '12 August 2024',
      leadStatus: LeadStatus.SALES_PITCH,
    },
    {
      leadName: 'Mariam Youssef',
      unitType: 'Standalone Villa',
      date: '10 August 2024',
      leadStatus: LeadStatus.SALES_PITCH,
    },
    {
      leadName: 'Mohamed Hisham',
      unitType: 'Apartment, Penthouse',
      date: '12 August 2024',
      leadStatus: LeadStatus.SALES_PITCH,
    },
    {
      leadName: 'Abdelwahab Mohamed',
      unitType: 'Standalone Villa',
      date: '10 August 2024',
      leadStatus: LeadStatus.DEAL_CLOSING,
    },
    {
      leadName: 'Amr Adel',
      unitType: 'Apartment, Penthouse',
      date: '12 August 2024',
      leadStatus: LeadStatus.DEAL_CLOSING,
    },
    {
      leadName: 'Haitham ElMasry',
      unitType: 'Standalone Villa',
      date: '12 August 2024',
      leadStatus: LeadStatus.DEAL_CLOSING,
    },
    {
      leadName: 'Barak Samir',
      unitType: 'Apartment, Penthouse',
      date: '12 August 2024',
      leadStatus: LeadStatus.DEAL_CLOSING,
    },
    {
      leadName: 'Lina Walid',
      unitType: 'Standalone Villa',
      date: '12 August 2024',
      leadStatus: LeadStatus.DEAL_CLOSING,
    },
  ];

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myLeads')}</h1>

      <div className={styles['sub-header']}>
        <div className={styles['filters']}>
          <label htmlFor="status">Filter By</label>
          <MultiSelectDropdown
            name="status"
            options={statusesOptions}
            placeholder={t('status')}
            onChange={(value) => {
              setSelectedStatuses(value);
            }}
            className={styles['select']}
          />
        </div>
        <Link
          to={ROUTES['NewDeal'].path}
          className={styles['deal-anchor']}
          onClick={() => analyticsCreateLeadCustomEvent(analyticsEventParams)}
        >
          <img src={plusIcon} alt="plus-icon" />
          {t('newLead')}
        </Link>
      </div>
      <div className="analytics-table">
        <table>
          <thead>
            <tr className="manager-tr">
              <th className="manager-th">{t('Lead Name')}</th>
              <th className="manager-th">{t('Unit type')}</th>
              <th className="manager-th">{t('Date')}</th>
              <th className="manager-th">{t('Lead STATUS')}</th>
              <th className="manager-th">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {leadsData?.map((item) => {
              return (
                <tr className="manager-tr sales-man-tr" key={uuidv4()}>
                  <td className="manager-td--name ">{item.leadName}</td>
                  <td className="sales-man-td">{item.unitType}</td>
                  <td className="sales-man-td"> {item.date}</td>
                  <td className="manager-td--status sales-man-td">
                    {renderLeadStatusIcon(item.leadStatus)}
                  </td>
                  <td className="sales-man--link">
                    <Link to={ROUTES['DealDetails'].getPath?.('1', '1')}>
                      {t('leadDetails')} <img src={arrowIcon} alt="arrow" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {currentPage} - {10}{' '}
            </span>{' '}
            {t('outOf')} <span>{leadsData.length} </span>
            {t('leads')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={10}
            pageSize={5}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default MyDeals;
