import { Route, UserRole } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import Destination from '../pages/destination/destination';
import MyDeals from '../pages/my-deals/my-deals';
import NewDeal from '../pages/new-deal/new-deal';
import DealDetails from '../pages/deal-details/deal-details';
import Updates from '../pages/updates/updates';
import PrimaryUnits from '../pages/primary-units/primary-units';

export const ROUTES: Record<string, Route> = {
  Login: {
    path: '/',
    component: Login,
    public: true,
    role: UserRole.ALL,
  },
  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: true,
  },
  MyDeals: {
    path: '/my-deals',
    component: MyDeals,
    public: true,
  },
  NewDeal: {
    path: '/new-deal',
    component: NewDeal,
    public: true,
  },
  DealDetails: {
    path: '/deal-details/:dealId/:countryId',
    getPath: (dealId: string, countryId: string) =>
      `/deal-details/${dealId}/${countryId}`,
    component: DealDetails,
    public: true,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: true,
  },
  PrimaryUnits: {
    path: '/primary-units',
    component: PrimaryUnits,
    public: true,
  },
  CompareProperties: {
    path: '/compare-properties',
    component: PrimaryUnits,
    public: true,
  },
  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: string) => `/unit-details/${unitId}`,
    component: PrimaryUnits,
    public: true,
  },
};
