const endpointsPrefix = process.env['NX__SALESMAN_ENDPOINT_PREFIX'] ?? '';
export const ENDPOINTS = {
  listSalesManDestinations: {
    url: `${endpointsPrefix}/destinations`,
    method: 'GET',
  },
  getSalesManDestinationInfo: {
    url: (destinationSlug: string) =>
      `${endpointsPrefix}/destinations/${destinationSlug}`,
    method: 'GET',
  },
  getSalesManDestinationLocations: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/locations`,
    method: 'GET',
  },
  getSalesManDestinationFacilities: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/facilities`,
    method: 'GET',
  },
  getSalesManDestinationProjects: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/projects`,
    method: 'GET',
  },
  getSalesManDestinationFAQ: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/faqs`,
    method: 'GET',
  },
  getSalesManFilters: {
    url: `${endpointsPrefix}/filters`,
    method: 'GET',
  },
  getSalesManUnits: {
    url: (filters?: string): string =>
      `${endpointsPrefix}/units${filters ?? ''}`,
    method: 'GET',
  },
  addSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'POST',
  },

  deleteSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'DELETE',
  },
};
