import {
  SidebarComponent,
  SidebarMenuItem,
} from '@orascom/broker-sales-man-common-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Destination, Route } from '@orascom/api-interfaces';
import DealsIcon from '../../assets/icons/my-deals.svg?react';
import DealsActiveIcon from '../../assets/icons/my-deals-active.svg?react';
import DestinationsIcon from '../../assets/icons/destinations.svg?react';
import DestinationsActiveIcon from '../../assets/icons/destinations-active.svg?react';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import PrimaryIcon from '../../assets/icons/primary-units.svg?react';
import PrimaryActiveIcon from '../../assets/icons/primary-units-active.svg?react';
import { CommonEventParameters } from '@orascom/utils';
import {
  analyticsSalesManDestinationCustomEvent,
  analyticsSalesManDestinationsCustomEvent,
  analyticsSalesManPrimaryUnitsCustomEvent,
} from '../../utils/analytics-events';

export function Sidebar() {
  const { t } = useTranslation();
  const [loggingOut, setLoggingOut] = useState(false);
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [availableDestinations, setAvailableDestinations] = useState<
    Destination[]
  >([]);

  const logoutHandler = () => {
    setLoggingOut(true);
  };

  const isActive = (routeName: Route, id?: string | number) =>
    location.pathname === routeName.path ||
    location.pathname === routeName.getPath?.(id);

  useEffect(() => {
    DestinationUtils.listSalesManDestinations().then((res) => {
      setAvailableDestinations(res);
    });
  }, []);

  const analyticsSalesManEventParams: CommonEventParameters = {
    userId: '', // TODO: change to id from context userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };
  return (
    <SidebarComponent
      subtitle={t('internalSalesPortal')}
      loggingOut={loggingOut}
      logoutHandler={logoutHandler}
    >
      <SidebarMenuItem
        route={ROUTES['MyDeals']}
        icon={<DealsIcon />}
        activeIcon={<DealsActiveIcon />}
        isActive={
          isActive(ROUTES['MyDeals']) ||
          location.pathname.includes('deal-details')
        }
        label={t('myLeads')}
      />
      <SubMenu
        label={t('destinations')}
        icon={
          location.pathname.includes('destination') ? (
            <DestinationsActiveIcon />
          ) : (
            <DestinationsIcon />
          )
        }
        active={location.pathname.includes('destination')}
        open={subMenuOpen}
        onOpenChange={() => setSubMenuOpen(!subMenuOpen)}
        onClick={() =>
          analyticsSalesManDestinationsCustomEvent(analyticsSalesManEventParams)
        }
      >
        {availableDestinations.map((destination) => (
          <MenuItem
            key={destination.id}
            component={
              <Link to={ROUTES['Destination'].getPath?.(destination.slug)} />
            }
            active={isActive(ROUTES['Destination'], destination.slug)}
            onClick={() =>
              analyticsSalesManDestinationCustomEvent({
                name: destination.slug,
                userId: '', // TODO: change to id from context userContext.user?.id.toString(),
                timestamp: Date.now().toString(),
                portal: 'Sales Man',
                pageName: location.pathname,
              })
            }
          >
            {destination.name}
          </MenuItem>
        ))}
      </SubMenu>
      <SidebarMenuItem
        route={ROUTES['PrimaryUnits']}
        icon={<PrimaryIcon />}
        activeIcon={<PrimaryActiveIcon />}
        isActive={isActive(ROUTES['PrimaryUnits'])}
        label={t('primaryUnits')}
        onClick={() =>
          analyticsSalesManPrimaryUnitsCustomEvent(analyticsSalesManEventParams)
        }
      />
    </SidebarComponent>
  );
}
export default Sidebar;
